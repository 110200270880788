export const regexEmail =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => regexEmail.test(email);

export const isValidSsn = (language, ssn) => {
  return language === 'sv' ? isValidSwedishSsn(ssn) : isValidDob(ssn);
};

export const isValidSwedishSsn = (ssn) => {
  if (ssn.length === 11 && ssn.substr(6, 1) !== '-') return false;

  ssn = ssn.replace('-', '');

  if (ssn === '0000000000') return false;

  const month = ssn.substr(2, 2);
  const day = ssn.substr(4, 2);
  if (month > 12 || day > 31) return false;

  ssn = ssn
    .split('') // convert string to array
    .reverse(); // reverse order for Luhn

  // verify we got 10 digits, otherwise it is invalid
  if (ssn.length !== 10) {
    return false;
  }

  const sum = ssn
    // convert to number
    .map((n) => Number(n))
    // perform arithmetic and return sum
    .reduce((previous, current, index) => {
      // multiply every other number with two
      if (index % 2) current *= 2;
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) current -= 9;
      // sum it up
      return previous + current;
    });

  // sum must be divisible by 10
  return sum % 10 === 0;
};

export const isValidDob = (ssn) => {
  if (ssn.length !== 8 || !/^\d+$/.test(ssn)) return false;

  const year = ssn.substr(0, 4);
  const month = ssn.substr(4, 2);
  const day = ssn.substr(6, 2);

  return year > 1900 && year < 2011 && month >= 1 && month <= 12 && day >= 1 && day <= 31;
};
