import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Row, Col } from 'reactstrap';
import getLanguage from '../services/helpers';
import getPath from '../assets/routes/paths';

import logotypeGolfamoreWhite from '../assets/img/logo-golfamore-white.png';

const languageCode = getLanguage();
const showContest = ['sv', 'dk', 'no', 'en'].includes(languageCode);

const Header = React.memo(function Header({
  bgImage,
  black,
  title,
  subtitle,
  button,
  button2,
  button3,
  button4,
  headerSize,
  imgTop,
  logo,
  // firstPage,
  biggerTitle,
  infoFrame,
  style
}) {
  const headerClass = headerSize ? ` page-header-${headerSize}` : '';
  const contestClass = showContest ? ' margin-top-banner' : '';
  const infoFrameClass = infoFrame ? ' page-header-infoframe' : '';
  const blackClass = black ? ' page-header-black' : '';
  const imgTopClass = imgTop ? ' top' : '';

  return (
    <>
      <div
        id="header-image"
        className={`page-header${headerClass}${contestClass}${infoFrameClass}${blackClass}${imgTopClass}`}
        style={{ backgroundImage: `url(${bgImage}`, ...style }}
      >
        <div className="content-center">
          <Container>
            <Row>
              <Col xs="12">
                {/* <div className="d-none d-md-block"> */}
                {logo && (
                  <div className="logo">
                    <img src={logotypeGolfamoreWhite} alt="logo" style={{ width: '50%' }} />
                  </div>
                )}
                {title && (
                  <h3
                    className={`title title-uppercase bold mb-0 ${biggerTitle && 'big'} ${languageCode === 'de' && 'de'}`}
                    style={{ textShadow: '2px 2px 2px rgba(0,0,0,0.6)' }}
                  >
                    {title}
                  </h3>
                )}
                {subtitle && <h3 className="description mt-0">{subtitle}</h3>}
                {/* </div> */}
                {button && (
                  <Button className="mt-4" color={button.color || 'neutral'} onClick={button.onClick}>
                    {button.title}
                  </Button>
                )}
                {button2 && (
                  <Button className="mt-4 ml-2" color={button2.color || 'neutral'} onClick={button2.onClick}>
                    {button2.title}
                  </Button>
                )}
                {button3 && (
                  <Button className="mt-4 ml-2" color={button3.color || 'neutral'} onClick={button3.onClick}>
                    {button3.title}
                  </Button>
                )}
                {button4 && (
                  <Button className="mt-4 ml-2" color={button4.color || 'neutral'} onClick={button4.onClick}>
                    {button4.title}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {infoFrame && (
        <>
          <div className="content-bottom shadow-4">
            <Row>
              <Col xs="12">
                <Row>
                  <Col xs="12" sm="4">
                    <h5 className="bold" style={{ color: '#000' }}>
                      {infoFrame.title1}
                    </h5>
                    <p>{infoFrame.text1}</p>
                  </Col>

                  <Col xs="12" sm="4">
                    <h5 className="bold" style={{ color: '#000' }}>
                      {infoFrame.title2}
                    </h5>
                    <p>{infoFrame.text2}</p>
                  </Col>

                  <Col xs="12" sm="4">
                    <h5 className="bold" style={{ color: '#000' }}>
                      {infoFrame.title3}
                    </h5>
                    <p>{infoFrame.text3}</p>
                  </Col>
                </Row>
                {infoFrame.bottomText && (
                  <>
                    <Row>
                      <Col>
                        <hr className="mt-4 mb-4" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-align-center">
                        <Link to={getPath(null, 'HowItWorks')} className="bold">
                          {infoFrame.bottomText}
                          <svg width="18" height="18" role="presentation" viewBox="0 0 24 24">
                            <g fill="currentColor" fillRule="evenodd">
                              {/* eslint-disable-next-line max-len */}
                              <path d="M5 13h11.17l-4.88 4.88a1 1 0 000 1.42 1 1 0 001.41 0l6.59-6.59a1 1 0 000-1.41l-6.58-6.6a1 1 0 10-1.41 1.41L16.17 11H5a1 1 0 00-1 1 1 1 0 001 1z" />
                            </g>
                          </svg>
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div id="empty" className="position-content-below-page-header" />
        </>
      )}
    </>
  );
});

export default Header;
