import React, { useEffect, useState } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Container, Row, Col, Form, Input, Spinner } from 'reactstrap';
import Helmet from '../components/Helmet';
import Button from '../components/Button';
import Header from 'components/Header';
import getPath from 'assets/routes/paths';
import { regexEmail } from 'services/validation';
import * as api from '../services/api';

const lead = 'DK-DanskGolfShow-2502';

function DanskGolfShow({ translate }) {
  document.documentElement.classList.remove('nav-open');

  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    document.body.classList.add('leads');
    window.scrollTo(0, 0);
    return function cleanup() {
      document.body.classList.remove('leads');
    };
  }, []);

  const updateEmail = (e) => {
    const val = e.target.value;

    if (regexEmail.test(val)) {
      setEmailInvalid(false);
    }

    setEmail(e.target.value);
  };

  const sendForm = async (e) => {
    e.preventDefault();

    setSuccess('');
    setError('');

    if (!regexEmail.test(email)) {
      setEmailInvalid(true);
      return;
    }

    setInProgress(true);

    const { error: err } = await api.addLead(email, lead);
    console.log('err', err);
    if (!err) {
      setSuccess('Tak for din tilmelding!');
      setEmail('');
    } else {
      setError(err);
    }

    setInProgress(false);
  };

  return (
    <>
      <Helmet />
      <Header bgImage="/react-assets/img/penati.png" headerSize="xs" imgTop logo title="Har du talent for at vinde?" />

      <div className="section section-gray mt-1">
        <Container className="small-width">
          <Row>
            <Col className="ml-auto mr-auto">
              <h3 className="title text-center">Tilmeld dig vores nyhedsbrev med din e-mail her og deltag i vores chipping-konkurrence!</h3>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section">
        <Container className="small-width">
          <Row>
            <Col xs="0"></Col>
            <Col>
              <h3 className="title">
                <Translate id="default.your-email" />
              </h3>

              <Form className="compete">
                <Row>
                  <Col>
                    <Input
                      placeholder={translate('default.email')}
                      type="email"
                      name="email"
                      bsSize="lg"
                      value={email}
                      onChange={updateEmail}
                      invalid={emailInvalid}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="small mt-1">
                      <Translate
                        id="contact.integrity"
                        data={{
                          link: getPath('dk', 'Privacy')
                        }}
                      />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    {error && <p className="alert alert-danger">{error}</p>}
                    {success && <p className="alert alert-success">{success}</p>}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right mr-3">
                    <Button block text={translate('default.send')} color="success" onClick={sendForm} disabled={inProgress}>
                      {inProgress && <Spinner color="success" size="sm" />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ position: 'relative' }}>
        <img src="/react-assets/img/pink-socks.jpg" alt="Buy Golfamore" style={{ width: '100%', minHeight: '150px' }} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          <h2 className="medium" style={{ color: '#fff' }}>
            <Translate id="default.play-golf" />
          </h2>
          <Button text={translate('default.buy-golfhaftet')} link="Webshop" />
        </div>
      </div>
    </>
  );
}

export default withLocalize(DanskGolfShow);
